import { withStyles } from '@material-ui/core/styles';
//import { alpha } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export default withStyles((theme) => ({
    root: {
        borderRadius: 0,
        textTransform: 'uppercase',
        fontWeight: theme.typography.fontWeightBold,
        padding: theme.spacing(1, 2),
        fontSize: theme.typography.pxToRem(14),
        boxShadow: 'none',
        '&:active, &:focus': {
            boxShadow: 'none',
        },
    },
    outlinedPrimary: {
        padding: '5px 15px',
        border: `2px solid ${theme.palette.primary.main}`,
        '&:hover': {
            border: `2px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.contrastText,
            background: theme.palette.primary.main,
        }
    },
    outlinedSecondary: {
        padding: '5px 15px',
        color: theme.palette.secondary.contrastText,
        border: `2px solid ${theme.palette.secondary.contrastText}`,
        '&:hover': {
            border: `2px solid ${theme.palette.secondary.main}`,
            color: theme.palette.secondary.contrastText,
            background: theme.palette.secondary.main,
        }
    },
    sizeSmall: {
        padding: theme.spacing(1, 3),
        fontSize: theme.typography.pxToRem(13),
    },
    sizeLarge: {
        padding: theme.spacing(2, 5),
        fontSize: theme.typography.pxToRem(16),
    },
}))(Button);