import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import Signup from "../../components/Signup/Signup"
import Copyright from '../../components/Main/Copyright';

// imgs
//import logoi from "../../static/images/tonic3_logo_w.svg";
import logo from "../../static/images/tonic3_logo.svg";
import backgroundImage from "../../static/images/bg_mainlanding.webp";

TagManager.dataLayer({
  dataLayer: {
    event: "pageview",
    pagePath: "/signup",
    pageTitle: "Sign Up page",
  },
});

const styles = (theme) => ({
  container: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
  },
  logotypeContainer: {
    position: "relative",
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "50%",
      display: "none",
    },
  },
  imgBackground: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: "#000", // Average color of the background image.
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -2,
  },
  logotypeImage: {
    width: 165,
    marginBottom: theme.spacing(2),
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 66,
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },
});
function Login(props) {
  const { classes } = props;

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>Talks!</Typography>
        <div className={classes.imgBackground} />
      </div>
      <Signup />
      <Typography color="primary" className={classes.copyright}>
        <Copyright />
      </Typography>
    </Grid>
  );
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Login));
