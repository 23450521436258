import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar'
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '../Main/Typography';
//import Link from '@material-ui/core/Link';
import Button from '../Main/Button';
import prestonImage from '../../static/images/preston.webp';
import backgroundImage from '../../static/images/backgroundEvent.png';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// swiper bundle styles
import 'swiper/swiper-bundle.min.css'

// swiper core styles
import 'swiper/swiper.min.css'

// modules styles
import '../../shared/themes/styles/navigation.min.css'
import '../../shared/themes/styles/pagination.min.css'

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

const styles = (theme) => ({
    root: {
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: 'bottom',
        backgroundColor: theme.palette.background.custom1,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    },
    container: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        display: 'flex',
        position: 'relative',
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        backgroundColor: theme.palette.secondary.main,
        marginBottom: theme.spacing(2),
    },
    item: {
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 80px)',
        [theme.breakpoints.down("md")]: {
            alignItems: 'center',
        },

    },
    button: {
        marginTop: theme.spacing(2),
        display: 'inline-block',
        width: 'fit-content',
    },
    image: {
        height: 55,
    },
    title: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    cardTitle: {
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
    cardSubtitle: {
        color: theme.palette.text.primary,
    },
    cardSubtitle2: {
        color: theme.palette.text.hint,
    },
    cardText: {
        marginTop: theme.spacing(2),
        color: theme.palette.text.primary,
    },
    swiperCont: {
        paddingBottom: theme.spacing(5),
    }
});
const eventDetails = [
    {
        id: 1,
        title: "Validating Key Concepts For AR / VR Prototypes",
        description: [
            "During our third session, we’ll discuss productizing, the importance of using concepts to plan out AR/VR implementation, and how to use these concepts successfully.",
            "We will underline key insights like understanding your audience, creating Measurement Markers and  User Testing to guarantee an immersive experience."],
        linkUrl: "https://forms.gle/3NJbgGyfdF1UX63Y9",
        linkText: "AR/VR Survey",
    },
    {
        id: 2,
        title: "Forward-thinking Steps for Incorporating AR/VR into your Business Products",
        description: [
            "During our second session, we’ll go over how to actually implement AR/VR in your business strategy by covering topics such as identifying areas where AR/VR can be used, strategic planning, and the process of successfully introducing AR/VR to your products and services.",
            "If you want to know more on how to introduce your product or service to Augmented and Virtual reality technologies, this is the talk you mustn't miss.",
        ],
        linkUrl: "https://vimeo.com/642436937/60d182630f",
        linkText: "See the webinar here",
    },
    {
        id: 3,
        title: "Augmented and Virtual Ecosystem Introduction",
        description: [
            'This new three-part webinar series will focus on how to implement augmented and virtual reality to improve your business’s product life cycle.',
            'During our first session, we’ll build a foundational understanding of the augmented and virtual reality ecosystem by covering topics such as the importance of investing in these mediums, choosing a device suitable for your audience, and essential industry insights and key innovative research.'
        ],
        linkUrl: "https://vimeo.com/624508119/74d39de2dc",
        linkText: "See the webinar here",        
    },
];
function SectionEventDetails(props) {
    const { classes } = props;

    return (
        <section className={classes.root}>
            <Typography color="inherit" align="center" variant="h1" marked="center" className={classes.title}>
                About the Event
            </Typography>

            <Container className={classes.container}>
                <Swiper
                    className={classes.swiperCont}
                    spaceBetween={20}
                    pagination={{ clickable: false }}
                    loop={true}
                    //autoplay={{ delay: 4000, disableOnInteraction: false }}
                    breakpoints={{
                        640: {
                            slidesPerView: 1,
                            slidesPerGroup: 1,
                            //spaceBetween: 20
                        },
                        // when window width is >= 
                        800: {
                            slidesPerView: 2,
                            slidesPerGroup: 2,
                            //spaceBetween: 20
                        },
                        // when window width is >= 
                        998: {
                            slidesPerView: 3,
                            slidesPerGroup: 3,
                            //spaceBetween: 20
                        }
                    }}
                    navigation={
                        true
                        //{ nextEl: '.swiper-button-next-unique', prevEl: '.swiper-button-prev-unique' }
                    }
                >
                    
                    <SwiperSlide>
                        <Card variant="outlined" className={classes.item}>
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                            >
                                <Avatar src={prestonImage} className={classes.avatar}>PM</Avatar>

                                <Typography variant="h4" className={classes.cardTitle}>
                                    Preston McCauley
                                </Typography>
                                <Typography variant="h6" className={classes.cardSubtitle2}>
                                    Principal UX Strategist &amp; AR/VR Design and Innovation
                                </Typography>
                            </Grid>
                            <Typography variant="body1" className={classes.cardText}>
                                {'In his twenty years of experience design, he has held many leadership roles, and he is often brought in to help others solve complicated business and design problems. Through his career, he has had the opportunity to work in many industries and across several verticals in both the corporate and start-up world.'}
                            </Typography>
                            <Button
                                variant="outlined"
                                color="primary"
                                target="_blank"
                                href="https://youtu.be/Kl0JJse4qrY"
                                className={classes.button}
                                fullWidth={false}
                            >
                                VR Capabilities
                            </Button>
                        </Card>
                    </SwiperSlide>
                    {eventDetails.map(({ title, description, linkUrl, linkText }, i) => (
                        <SwiperSlide key={i}>
                            <Card variant="outlined" className={classes.item}>
                                <Typography variant="h4" className={classes.cardTitle}>
                                    {title}
                                </Typography>
                                {/*
                                <Typography variant="h2" className={classes.cardSubtitle}>
                                    Joe Edwards
                                </Typography>
                                */}
                                {description.map((paragraph, j) => (
                                    <Typography key={j} variant="body1" className={classes.cardText}>
                                        {paragraph}
                                    </Typography>
                                ))}
                                {linkUrl && linkText && (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        target="_blank"
                                        href={linkUrl}
                                        className={classes.button}
                                        fullWidth={false}
                                    >
                                        {linkText}
                                    </Button>
                                )}
                            </Card>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Container>
        </section>
    );
}

SectionEventDetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SectionEventDetails);