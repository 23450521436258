import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import Landing from "../pages/landing";
import TagManager from "react-gtm-module";

// components
//import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
//import Landing from "../pages/landing";
import Virtualoffices from "../pages/virtualoffices/Virtualoffices.js";

// context
//import { SessionContext } from "../context/SessionContext";

TagManager.initialize({
    gtmId: "GTM-MZ7RFJB",
});
export default function App() {
    // global
    //const { session } = useContext(SessionContext);
    //const isAuthenticated = Boolean(session.authToken);

    return (
        <Router basename={process.env.PUBLIC_URL}>
            <Switch>
                <Route exact path="/" render={() => <Redirect to="/app" />} />
                <Route
                    exact
                    path="/index.html"
                    render={() => <Redirect to="/app" />}
                />
                <Route
                    exact
                    path="/virtualoffices"
                    component={Virtualoffices}
                />
                <Route exact path="/app" component={Landing} />
                <Route exact path="/optionalApp" component={Landing} />
                <Route exact path="/signup" component={Login} />
                <Route component={Error} />
            </Switch>
        </Router>
    );

    // #######################################################################
    /*
    function PrivateRoute({ component, ...rest }) {
        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated ? (
                        React.createElement(component, props)
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/signup",
                                state: {
                                    from: props.location,
                                },
                            }}
                        />
                    )
                }
            />
        );
    }
    

    function PublicRoute({ component, ...rest }) {
        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated ? (
                        <Redirect
                            to={{
                                pathname: "/",
                            }}
                        />
                    ) : (
                        React.createElement(component, props)
                    )
                }
            />
        );
    }
    */
}
