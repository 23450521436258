import { Input } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import axios from "axios";
import React, { useState } from "react";

const useStyles = makeStyles({
    form: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        paddingInline: "3rem",
        paddingBlock: "2rem",
        gap: "1rem",
        backgroundColor: "white",
        borderRadius: "7px",
        border: "1px solid lightgray",
        marginTop: "3rem",
        marginInline: "1rem",
    },
    formTitle: {
        color: "white",
        backgroundColor: "orangered",
        paddingInline: "1rem",
        paddingBlock: ".5rem",
        borderRadius: "5px",
        border: "1px solid lightgray",
    },
    input: {
        width: "100%",
    },
    buttonsContainer: {
        marginTop: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        gap: ".5rem",
    },
    anonButton: {
        fontSize: ".8rem",
        background: "transparent",
        borderWidth: 0,
        cursor: "pointer",
        color: "gray",
    },
    signInButton: {
        fontSize: "1.3rem",
        borderColor: "lightgray",
        background: "orangered",
        color: "white",
        borderWidth: 1,
        borderRadius: "5px",
        width: "100%",
        cursor: "pointer",
        paddingBlock: ".5rem",
        "&:disabled": {
            cursor: "not-allowed",
            background: "gray",
        },
    },
});

const TrackModal = ({ setIframeUrl }) => {
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(true);
    const [form, setForm] = useState({
        email: "",
        firstName: "",
    });

    const trackApiClient = axios.create({
        baseURL: `${process.env.REACT_APP_TRACK_API_URL}/salesforce`,
    });

    const classes = useStyles();

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const res = await trackApiClient.post("/lead", form);
            setIframeUrl(
                (iframe) => `${iframe}?id=${res.data.applicationUserId}`
            );
        } catch (error) {
            console.log(error);
        }

        setLoading(false);
        setModalOpen(false);
    };

    return (
        <>
            {modalOpen && (
                <form className={classes.form} onSubmit={onSubmit}>
                    <h2 className={classes.formTitle}>
                        VIRTUAL OFFICES EXPERIENCE
                    </h2>
                    <Input
                        className={classes.input}
                        type={"email"}
                        placeholder="Email"
                        value={form.email}
                        onChange={(e) =>
                            setForm({
                                ...form,
                                email: e.target.value,
                            })
                        }
                    />
                    <Input
                        className={classes.input}
                        type={"text"}
                        placeholder="First name"
                        value={form.firstName}
                        onChange={(e) =>
                            setForm({
                                ...form,
                                firstName: e.target.value,
                            })
                        }
                    />
                    <div className={classes.buttonsContainer}>
                        <button
                            className={classes.signInButton}
                            disabled={form.email.length === 0}
                            type="submit"
                        >
                            {loading ? "Loading..." : "JOIN"}
                        </button>
                        <button
                            className={classes.anonButton}
                            onClick={() => setModalOpen(false)}
                        >
                            CONTINUE AS ANONYMOUS
                        </button>
                    </div>
                </form>
            )}
        </>
    );
};
export default TrackModal;
