import { CountdownProvider } from './CountdownContext';
import { LayoutProvider } from './LayoutContext';
//import { UserProvider } from './UserContext';
import { LocaleProvider } from './LocaleContext';
import { SessionProvider } from './SessionContext';
import { EventsProvider } from './EventsContext';
import { combineComponents } from '../utils/combineComponents';

export const AppContextProvider = combineComponents(
    LayoutProvider,
    SessionProvider,
    LocaleProvider,
    CountdownProvider,
    EventsProvider,
);