import Axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';

const LOCALE = `${API_URL}/locale/country`;
const COUNTDOWN = `${API_URL}/events`;
const LOGIN = `${API_URL}/users/login`;
const FORGOT = `${API_URL}/forgot`;
const TRACK_EVENT = `${API_URL}/track`;

const getCountry = () => Axios.get(LOCALE).then((response) => response.data);

const getCountdown = () => Axios.get(COUNTDOWN).then((response) => response.data);
const getEventById = (id) => Axios.get(COUNTDOWN+'/'+id).then((response) => response.data);

const login = (data) => Axios.post(LOGIN, data).then((response) => response.data);

const forgot = (data) => Axios.post(FORGOT, data).then((response) => response.data);

const trackEvent = (data, token) =>
  Axios.post(TRACK_EVENT, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => response.data);

const services = {
  IDLE: '',
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
  getCountry,
  getCountdown,
  getEventById,
  login,
  forgot,
  trackEvent,
};
export default services;