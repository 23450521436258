import React, { useContext, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { FormattedMessage } from "react-intl";
import Typography from "../Main/Typography";
import service from "../../shared/services";
import { makeStyles } from "@material-ui/styles";
import { EventContext } from "../../context/EventsContext";

const CountdownContainer = styled.div`
    background: #000;
    box-shadow: 0px 3px 6px #2e31914d;
    border: 1px solid #ff4400;
    display: flex;
    justify-content: space-around;
    margin: 1em 0;
    border-radius: 5px;
    & > .unit {
        margin: 1em 0;
        text-align: center;
        width: 75px;
        border-left: 1px dashed #ff4400;
        padding: 0 0.4em;
        &:first-child {
            border: none;
        }
        & > .value {
            color: #fff;
            font-size: 2em;
            line-height: 1em;
            margin: 0 10px;
        }
        & > .name {
            color: #ffffff;
            font-size: 1em;
            text-transform: uppercase;
        }
        & > .color-red {
            color: #ff4400;
        }
    }
    & > .unit:last-child {
        box-shadow: none;
    }
    @media screen and (min-width: 800px) {
        & > .unit {
            width: 118px;
            & > .value {
                font-size: 4em;
            }
            & > .name {
                font-size: 1.2em;
            }
        }
    }
`;

const useStyles = makeStyles((theme) => ({
    countdownTitle: {
        textTransform: "uppercase",
        fontWeight: "bold",
        fontSize: 20,
        marginTop: theme.spacing(2),
        color: "white",
    },
}));

const renderer = (days, hours, minutes, seconds) => {
    return (
        <CountdownContainer>
            <div className="unit">
                <div className="value color-red">
                    {days <= 9 && 0}
                    {days}
                </div>
                <div className="name color-red">
                    <FormattedMessage id="COUNTDOWN.DAYS" />
                </div>
            </div>
            <div className="unit">
                <div className="value color-red">
                    {hours <= 9 && 0}
                    {hours}
                </div>
                <div className="name color-red">
                    <FormattedMessage id="COUNTDOWN.HOURS" />
                </div>
            </div>
            <div className="unit">
                <div className="value">
                    {minutes <= 9 && 0}
                    {minutes}
                </div>
                <div className="name">
                    <FormattedMessage id="COUNTDOWN.MINUTES" />
                </div>
            </div>
            <div className="unit">
                <div className="value">
                    {seconds <= 9 && 0}
                    {seconds}
                </div>
                <div className="name">
                    <FormattedMessage id="COUNTDOWN.SECONDS" />
                </div>
            </div>
        </CountdownContainer>
    );
};

export default function CountdownComp({ setIsEventLive }) {
    const classes = useStyles();

    // Global
    const { activeEvent, status } = useContext(EventContext);

    // Local
    const [cdStreaming, setCdStreaming] = useState(0);
    const [serverStatus, setServerStatus] = useState(service.IDLE);

    const onComplete = useCallback(() => {
        setIsEventLive(true);
    }, [setIsEventLive]);

    useEffect(() => {
        if (status === "Loaded") {
            if (activeEvent && activeEvent.ms) {
                if (activeEvent.ms > 1000) {
                    setCdStreaming(Date.now() + activeEvent.ms);
                    setServerStatus(service.SUCCESS);
                    setIsEventLive && setIsEventLive(false);
                } else {
                    setCdStreaming(0);
                    setIsEventLive && setIsEventLive(true);
                }
            } else {
                alert("Problems trying to get data. Please try again later.");
            }
        }
    }, [activeEvent, status, setIsEventLive]);

    return (
        <>
            <Typography
                className={classes.countdownTitle}
                color="inherit"
                align="center"
                variant="h5"
                marked="center"
            >
                {cdStreaming > 0 ? "Countdown to the event" : ""}
            </Typography>

            {serverStatus === service.SUCCESS && (
                <Countdown
                    date={cdStreaming}
                    renderer={({ days, hours, minutes, seconds }) =>
                        renderer(days, hours, minutes, seconds)
                    }
                    onComplete={onComplete}
                />
            )}
        </>
    );
}
