import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import TagManager from "react-gtm-module";
import Countdown from "../../components/Countdown/Countdown";
import TrackModal from "../../components/TrackModal";
import StreamingModal from "./StreamingModal";

TagManager.dataLayer({
    dataLayer: {
        event: "pageview",
        pagePath: "/virtualoffices",
        pageTitle: "Arvrseries page",
    },
});

const useStyles = makeStyles((theme) => ({
    containerIframe: {
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        position: "absolute",
        top: 0,
        left: 0,
    },
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        top: 0,
        left: 0,
    },
    iframe: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        border: "none",
    },
}));

function Virtualoffices() {
    const [iframeUrl, setIframeUrl] = useState(process.env.REACT_APP_TOUR_URL);
    const [isEventLive, setIsEventLive] = useState(false);

    const classes = useStyles();

    return (
        <div className={classes.containerIframe}>
            <iframe
                src={iframeUrl}
                width="100%"
                height="100%"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                className={classes.iframe}
                title="Virtual Offices"
            />
            <div className={classes.container}>
                {!isEventLive && (
                    <>
                        <Countdown setIsEventLive={setIsEventLive} />
                        <TrackModal setIframeUrl={setIframeUrl} />
                    </>
                )}{" "}
                {isEventLive && <StreamingModal />}
            </div>
        </div>
    );
}

export default withRouter(Virtualoffices);
