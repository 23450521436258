import React from "react";
import SectionHero from '../../components/Sections/SectionHero';
import SectionEventDetails from '../../components/Sections/SectionEventDetails';
import SectionVideos from "../../components/Sections/SectionVideos";
import SectionInstagram from "../../components/Sections/SectionInstagram";
import Footer from '../../components/Footer/Footer';
import { withRouter } from "react-router-dom";
import TagManager from 'react-gtm-module'
//import { signOut } from "../../context/SessionContext";
//import { useLocation } from "react-router-dom";
//import * as QueryString from "query-string"
import Understand from "../../components/Main/Understand";

TagManager.dataLayer({
  dataLayer: {
    event: 'pageview',
    pagePath: '/app',
    pageTitle: 'Landing page',
  },
});

function Landing(props) {
  //const location = useLocation();
  //console.log("location", location);
/*
  const [cdStreaming, setCdStreaming] = useState(0);
  useEffect(() => {
    const params = QueryString.parse(props.location.search);
    if (params && Boolean(params.cdstreaming)) {
      setCdStreaming(Date.now() + parseInt(params.cdstreaming));
    }
  }, [props]);
*/
  return (
    <React.Fragment>
      <SectionHero  imageBackground={!props.location.pathname.includes("optionalApp")} /> { /* date={cdStreaming} */}
      <SectionEventDetails />
      <SectionVideos />
      <SectionInstagram />
      <Footer />
      {/*
      <Link color="primary" onClick={() => signOut(props.history)}>
        Sign Out
      </Link>
      */}
      <Understand />
    </React.Fragment>
  );
}

export default withRouter(Landing);