import React, { createContext, useState, useEffect } from 'react';
import service from '../shared/services';

//const ACTIVE_EVENT_ID = 4;

export const EventContext = createContext({
  status: 'Loading',
  events: null,
  activeEvent: null,
  getEvents: () => null,
  getEventById: (id) => null,
});


export const EventsProvider = ({ children }) => {
  const [status, setStatus] = useState('Loading');
  const [events, setEvents] = useState(null);
  const [activeEvent, setActiveEvent] = useState(null);

  async function getEvents() {
    await service.getCountdown().then((eventDet) => {
      let ACTIVE_EVENT_ID = eventDet.length - 1;
      setEvents(eventDet);
      setActiveEvent(eventDet[ACTIVE_EVENT_ID]);
      setStatus('Loaded')
    });
  }

  async function getEventById(id) {
    await service.getEventById(id).then((eventDet) => {
      if(eventDet[0].vimeoChatUrl !== activeEvent.vimeoChatUrl || eventDet[0].vimeoStreamingUrl !== activeEvent.vimeoStreamingUrl)
        setActiveEvent(eventDet[0]);
    });
  }
  
  useEffect(() => {
    getEvents();
  }, []);

  const value = React.useMemo(() => ({
    status, events, activeEvent, getEvents, getEventById
    // eslint-disable-next-line
  }), [status, events, activeEvent ]);

  return (
    <EventContext.Provider value={value}>
      {children}
    </EventContext.Provider>
  );
};
