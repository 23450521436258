
/** TODO: migrate to react-i18next */

import React, { createContext, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';

import enUS from '../shared/translates/messages/en-US.json';
//import esAR from '../shared/translates/messages/es-AR.json';

//import service from '../shared/services';

export const LocaleContext = createContext({
  locale: null,
  setCountry: () => { },
});

document.LOCALE_DATA = {};

export const LocaleProvider = ({ children }) => {
  const [country, setCountry] = useState(null);
  const [locale, setLocale] = useState(null);

  document.LOCALE_DATA = { ...locale };

  useEffect(() => {
    /**
    async function fetchData() {
      const { country } = await service.getCountry();
      setCountry(country);
    }
    fetchData();
     */
    setCountry('US');
  }, []);

  useEffect(() => {
    if (country) {
      switch (country) {
        /* descomentar
        case 'AR':
          setLocale({
            country,
            locale: 'es',
            messages: esAR,
            region: 'CENTRO',
            video: process.env.REACT_APP_VIDEO_CENTRO,
          });
          break;
        case 'BR':
          setLocale({
            country,
            locale: 'pt',
            messages: ptBR,
            region: 'BR',
            video: process.env.REACT_APP_VIDEO_BR,
          });
          break;
          */
        default:
          setLocale({
            country: 'US',
            locale: 'en',
            messages: enUS,
            region: 'US',
            video: 'https://vimeo.com/579470856',
          });
          break;
      }
    } else {
      setLocale({
        country: 'US',
        locale: 'en',
        messages: enUS,
        region: 'US',
        video: 'https://vimeo.com/579470856',
      });
    }
  }, [country, setLocale]);

  return (
    <>
      {locale && (
        <IntlProvider messages={locale.messages} locale={locale.locale}>
          <LocaleContext.Provider value={{ locale, setCountry }}>
            {children}
          </LocaleContext.Provider>
        </IntlProvider>
      )}
    </>
  );
};
