import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Typography from "../Main/Typography";
//import CardActionArea from "@material-ui/core/CardActionArea";
//import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Link from '@material-ui/core/Link';
import Button from "../Main/Button";
import vid1 from "../../static/images/ytvid1.jpg";
import vid2 from "../../static/images/ytvid2.jpg";
import vid3 from "../../static/images/ytvid3.jpg";
import vid4 from "../../static/images/ytvid4.jpg";
import vid5 from "../../static/images/ytvid5.jpg";
import vid6 from "../../static/images/ytvid6.jpg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// swiper bundle styles
import "swiper/swiper-bundle.min.css";

// swiper core styles
import "swiper/swiper.min.css";

// modules styles
import "../../shared/themes/styles/navigation.min.css";
import "../../shared/themes/styles/pagination.min.css";

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper/core";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

const styles = (theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  cardTitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  cardSubtitle: {
    color: theme.palette.text.primary,
  },
  cardSubtitle2: {
    color: theme.palette.text.hint,
  },
  cardText: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  button: {
    marginTop: theme.spacing(2),
    textTransform: 'uppercase',
    fontWeight: 'bold',
    display: 'inline-block',
  },
  swiperCont: {
    paddingBottom: theme.spacing(5),
  },
  media: {
    height: 200,
  },
  subscribeButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderRadius: 0,
  },
});

const videos = [
  {
    title: "Tonic3 - About Us",
    description:
      "Humanizing digital connections through User Experience, Augmented and Virtual...",
    imgSrc: `${vid1}`,
    href: "https://www.youtube.com/watch?v=tjPzsHo8XLI&t=1s&ab_channel=Tonic3",
  },
  {
    title: "AR/VR at Tonic3",
    description:
      "The possibilities of using AR/VR to benefit your business are endless.It’s high...",
    imgSrc: `${vid2}`,
    href: "https://www.youtube.com/watch?v=f7aGYXiKF74&ab_channel=Tonic3",
  },
  {
    title: "Tonic3 Life",
    description:
      "At Tonic3 we have been humanizing digital connections for more than 25 years...",
    imgSrc: `${vid3}`,
    href: "https://www.youtube.com/watch?v=8CMjNB_9T1k&ab_channel=Tonic3",
  },
  {
    title: "Case Study- CityAR",
    description: "3D Augmented Reality for innovative and fun cities!",
    imgSrc: `${vid4}`,
    href: "https://www.youtube.com/watch?v=v3DewdmV_Ik&ab_channel=Tonic3",
  },
  {
    title: "Tonic3 Life - Our Clients",
    description:
      "At Tonic3 we have been humanizing digital connections for more than 25 years...",
    imgSrc: `${vid5}`,
    href: "https://www.youtube.com/watch?v=74sHPiswD70&ab_channel=Tonic3",
  },
  {
    title: "Tonic3 Travel Industry",
    description:
      "Venturing and innovating  in the airline industry in USA, Brazil and  the world...",
    imgSrc: `${vid6}`,
    href: "https://www.youtube.com/watch?v=a6zRDS4Ly3s&ab_channel=Tonic3",
  },
];

function SectionVideos(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Typography
        color="inherit"
        align="center"
        variant="h1"
        marked="center"
        className={classes.title}
      >
        Our YouTube Channel
      </Typography>

      <Button
        variant="outlined"
        color="primary"
        target="_blank"
        href="https://www.youtube.com/channel/UChXwjAc85HfHIlakQZhO0EA?sub_confirmation=1"
      >
        Subscribe
      </Button>

      <Container className={classes.container}>
        <Swiper
          className={classes.swiperCont}
          spaceBetween={20}
          pagination={{ clickable: true }}
          loop={true}
          //autoplay={{ delay: 4000, disableOnInteraction: false }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              //spaceBetween: 20
            },
            // when window width is >=
            800: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              //spaceBetween: 20
            },
            // when window width is >=
            998: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              //spaceBetween: 20
            },
          }}
          navigation={
            true
            //{ nextEl: '.swiper-button-next-unique', prevEl: '.swiper-button-prev-unique' }
          }
        >
          {videos.map((video, i) => (
            <SwiperSlide key={i}>
              <Card variant="outlined">
                <CardMedia className={classes.media} component="img" src={video.imgSrc} title={video.title}/>
                <CardContent>
                  <Typography variant="h4" className={classes.cardTitle}>
                    {video.title}
                  </Typography>
                  <Typography variant="body1" className={classes.cardText} component="p">{video.description}</Typography>
                  <Link variant="body2" className={classes.button} href={video.href} target="_blank">View More</Link>
                </CardContent>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  );
}

SectionVideos.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SectionVideos);
