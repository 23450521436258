import { Button, makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import TagManager from "react-gtm-module";
import { EventContext } from "../../context/EventsContext";
import { ReactComponent as StreamIcon } from "../../static/images/stream-icon.svg";

TagManager.dataLayer({
    dataLayer: {
        event: "pageview",
        pagePath: "/virtualoffices",
        pageTitle: "Arvrseries page",
    },
});

const useStyles = makeStyles((theme) => ({
    modal: {
        position: "fixed",
        left: "50%",
        width: "80%",
        backgroundColor: "white",
        borderRadius: "10px",
        zIndex: 200,
        overflow: "visible",
        [theme.breakpoints.up("sm")]: {
            top: "50%",
            transform: "translate(-50%, -50%)",
        },
        [theme.breakpoints.down("sm")]: {
            top: "7%",
            transform: "translate(-50%, 0)",
            overflowY: "auto",
        },
        [theme.breakpoints.down("md")]: {
            width: "90%",
        },
        maxHeight: "85vh",
    },
    container: {
        width: "100%",
        height: "100%",
        position: "relative",
        padding: ".5rem",
    },
    iframeStyledVideo: {
        [theme.breakpoints.up("md")]: {
            width: "70%",
            height: "640px",
            maxHeight: "calc(85vh - 1.5rem)",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: "300px",
            maxHeight: "60vh",
        },
    },
    iframeStyledChat: {
        height: "640px",
        maxHeight: "calc(85vh - 1.5rem)",
        [theme.breakpoints.up("sm")]: {
            width: "30%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
    },
    buttonLive: {
        "&.MuiButton-root": {
            marginTop: theme.spacing(2),
            padding: theme.spacing(1) + "px " + theme.spacing(2) + "px",
            borderRadius: 0,
            color: "#fff",
            backgroundColor: theme.palette.primary.main,
        },
    },
    "@keyframes changeColorSVG": {
        "0%": {
            fill: "#000",
        },
        "75%": {
            fill: "#fff",
        },
    },
    iconLive: {
        width: "30px",
        height: "30px",
        "& *": {
            animationName: "$changeColorSVG",
            animationDuration: "1s",
            animationIterationCount: "infinite",
        },
    },
    closeButton: {
        position: "absolute",
        top: "0",
        right: "0",
        width: "30px",
        height: "30px",
        backgroundColor: "rgba(0, 0, 0, .4)",
        border: "none",
        fontSize: "1.5rem",
        color: "lightgray",
        cursor: "pointer",
        borderRadius: "100%",
        transform: "translate(50%, -50%)",
        "&:hover": {
            color: "white",
        },
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
}));

const StreamingModal = () => {
    const [isOpen, setIsOpen] = React.useState(true);
    const { activeEvent } = useContext(EventContext);
    const classes = useStyles();

    return (
        <>
            {activeEvent && (
                <>
                    {isOpen && (
                        <>
                            <div className={classes.modal}>
                                <div className={classes.container}>
                                    <button
                                        className={classes.closeButton}
                                        onClick={() => setIsOpen(false)}
                                    >
                                        X
                                    </button>
                                    <iframe
                                        src={activeEvent.vimeoStreamingUrl}
                                        allow="autoplay; fullscreen; picture-in-picture"
                                        allowFullScreen
                                        className={classes.iframeStyledVideo}
                                        title="Vimeo video"
                                    />
                                    <iframe
                                        src={activeEvent.vimeoChatUrl}
                                        className={classes.iframeStyledChat}
                                        title="Vimeo chat"
                                    />
                                </div>
                            </div>
                            <div
                                onClick={() => setIsOpen(false)}
                                className={classes.overlay}
                            ></div>
                        </>
                    )}
                    {!isOpen && (
                        <Button
                            classes={{ root: classes.buttonLive }}
                            variant="contained"
                            color="primary"
                            startIcon={
                                <StreamIcon className={classes.iconLive} />
                            }
                            onClick={() => setIsOpen(true)}
                        >
                            ENTER STREAM
                        </Button>
                    )}
                </>
            )}
        </>
    );
};

export default StreamingModal;
