import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/styles";
import { useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '../Main/Button';
import Typography from '../Main/Typography';
import SectionHeroLayout from './SectionHeroLayout';
import Countdown from '../Countdown/Countdown'
import Signup from "../../components/Signup/Signup";
import CheckIcon from '@material-ui/icons/Check';

// context
import { SessionContext } from "../../context/SessionContext"

import backgroundImage from '../../static/images/landing-min.webp';
import logoImage from '../../static/images/tonic3_logo.svg';
import guiaVideo from '../../static/video/guia.mp4';
/** TODO: Sacar className de title. Hacerlo más global */

//import '../../shared/themes/styles/theme6.css'

const useStyles = makeStyles(theme => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: '#000', // Average color of the background image.
    backgroundPosition: 'center',
  },
  countdownTitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: 50,
  },
  container: {
    textAlign: 'center',
    zIndex: 9
  },
  button: {
    minWidth: 200,
    margin: theme.spacing(1),
  },
  buttonSignup: {
    minWidth: 'auto',
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      minWidth: 480,
    },

  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  logotypeContainer: {
    width: "60%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  logotypeImage: {
    width: 150,
  },
  logotypeText: {
    color: "white",
    fontWeight: 500,
    fontSize: 74,
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      fontSize: 48,
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    margin: 0,
    padding: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  videoStyled: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: "50%",
    top: "50%",
    objectFit: "cover",
    transform: "translate(-50%, -50%)",
    zIndex: "-1"
  }, 
  title:{
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: theme.spacing(2),
    color: theme.palette.secondary.contrastText,
    [theme.breakpoints.up('sm')]: {
      fontSize: 40
    }
  }
}));

const DialogTitle = (props) => {
  const classes = useStyles();
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.dialogTitle} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const CustomizedDialogs = (props) => {
  const classes = useStyles();
  const { open, handleClose } = props;
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullScreen={fullScreen}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Sign up to the event!
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <Signup handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

const SignUpButtonConf = (props) => {
  const classes = useStyles();
  const { handleClickOpen, isAuthenticated } = props;

  if (!isAuthenticated) {
    return (
      <Button size="large" variant="contained" color="primary" onClick={handleClickOpen} className={classes.buttonSignup}>
        <CheckIcon className={classes.extendedIcon} /> Sign up
      </Button>
    )
  } else {
    return (
      <Typography color="inherit" align="center" variant="h5" marked="center">Thanks for signing up</Typography>
    );
  }

}

function SectionHero(props) {
  const classes = useStyles();
  //const date = props.date;


  // global
  const { session } = useContext(SessionContext);
  const isAuthenticated = Boolean(session.authToken);

  // local
  const [open, setOpen] = useState(false);
  //const [cdStreaming, setCdStreaming] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
/*
  useEffect(() => {
    if (Boolean(date)) {
      setCdStreaming(date);
    }
  }, [date]);
*/
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://addevent.com/libs/atc/1.6.1/atc.min.js";
    script.async = true;
    script.defer = true;
    script.type = "text/javascript";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <SectionHeroLayout backgroundClassName={props.imageBackground ? classes.background : ""} >
      {!props.imageBackground && <video
        autoPlay
        loop
        muted
        className={classes.videoStyled}
      >
        <source src={guiaVideo} type="video/mp4" />
      </video>
      }
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: 'none' }} src={backgroundImage} alt="increase priority" />
      <div className={classes.logotypeContainer}>
        <img className={classes.logotypeImage} src={logoImage} alt="Tonic3" />
        <Typography className={classes.logotypeText}>Talks!</Typography>
      </div>
      <Typography className={classes.title} color="inherit" align="center" variant="h3" >Validating Key Concepts For AR / VR Prototypes</Typography>
      <Countdown  /> {/* date={cdStreaming} */}
      <SignUpButtonConf handleClickOpen={handleClickOpen} isAuthenticated={isAuthenticated} />
      <Container className={classes.container}>
        <a
          title="Add to Calendar"
          className="addeventatc"
          style={{ marginTop: '8px' }}
          data-id="Vm8607389"
          href="https://www.addevent.com/event/Vm8607389"
          target="_blank"
          rel="noopener noreferrer"
        >Add to Calendar</a>

        {/* <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          size="small"
          component="a"
          href="https://www.addevent.com/event/Vm8607389+outlookcom"
          target="_blank"
        >
          Add to Outlook Calendar
        </Button>
        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          size="small"
          component="a"
          href="https://www.addevent.com/event/Vm8607389+google"
          target="_blank"
        >
          Add to Google Calendar
        </Button> */}
      </Container>

      <CustomizedDialogs open={open} handleClose={handleClose} />

    </SectionHeroLayout>
  );
}

SectionHero.propTypes = {
  date: PropTypes.number,
};

export default SectionHero;