import tinycolor from "tinycolor2";

const primary = "#ff4400";
const secondary = "#dd2c00";
const warning = "#d50000";
const success = "#ff5722";
const info = "#37474f";

const lightenRate = 7.5;
const darkenRate = 15;
//See https://material-ui.com/customization/default-theme/#default-theme
const theme = {
    palette: {
        //type: 'dark',
        primary: {
            main: primary,
            light: tinycolor(primary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(primary)
                .darken(darkenRate)
                .toHexString(),
        },
        secondary: {
            main: secondary,
            light: tinycolor(secondary)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(secondary)
                .darken(darkenRate)
                .toHexString(),
            contrastText: "#FFFFFF",
        },
        warning: {
            main: warning,
            light: tinycolor(warning)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(warning)
                .darken(darkenRate)
                .toHexString(),
        },
        success: {
            main: success,
            light: tinycolor(success)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(success)
                .darken(darkenRate)
                .toHexString(),
        },
        info: {
            main: info,
            light: tinycolor(info)
                .lighten(lightenRate)
                .toHexString(),
            dark: tinycolor(info)
                .darken(darkenRate)
                .toHexString(),
        },
        text: {
            primary: "#4A4A4A",
            secondary: "#6E6E6E",
            hint: "#A3A3A3",
        },
        background: {
            default: "#FFFFFF",
            light: "#F3F5FF",
            //paper: "#fff",
            custom1: "#E5E5E5",
            custom2: "#2C2C2C",
        },
    },

    customShadows: {
        widget: "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A, 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        widgetDark: "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A, 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
        widgetWide: "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A, 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': { fontFamily: "'Lato', sans-serif" },
                body: { fontFamily: "'Lato', sans-serif" },
            },
        },
        MuiButton: {
            root: {
                borderRadius: 100,
                fontSize: "1.25rem",
                textTransform: "none",
            },
            sizeLarge: {
                fontSize: "1.25rem",
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: 10,
            },
        },
        MuiBackdrop: {
            root: {
                backgroundColor: "#4A4A4A1A",
            },
        },
        MuiMenu: {
            paper: {
                boxShadow: "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A, 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
            },
        },
        MuiSelect: {
            icon: {
                color: "#9a9a9a",
            },
        },
        MuiListItem: {
            root: {
                "&$selected": {
                    backgroundColor: "#F3F5FF !important",
                    "&:focus": {
                        backgroundColor: "#F3F5FF",
                    },
                },
            },
            button: {
                "&:hover, &:focus": {
                    backgroundColor: "#F3F5FF",
                },
            },
        },
        MuiTouchRipple: {
            child: {
                backgroundColor: "white",
            },
        },
        MuiTableRow: {
            root: {
                height: 56,
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: "1px solid rgba(224, 224, 224, .5)",
            },
            head: {
                fontSize: "0.95rem",
            },
            body: {
                fontSize: "0.95rem",
            },
        },
        MuiFab: {
            root: {
                fontWeight: "bold",
            },
        },
    },
};

export default theme;