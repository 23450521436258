import defaultTheme from "./default";

import { createTheme } from '@material-ui/core/styles';

import "@fontsource/lato"
// To change font family: https://fontsource.org/fonts

const overrides = {
    typography: {
        fontFamily: "'Lato', sans-serif",
        h1: {
            fontSize: "3rem",
            position: "relative",
        },
        h2: {
            fontSize: "2rem",
        },
        h3: {
            fontSize: "1.64rem",
        },
        h4: {
            fontSize: "1.5rem",
        },
        h5: {
            fontSize: "1.285rem",
        },
        h6: {
            fontSize: "1.142rem",
        }

    },
};

const themeHoc = {
    default: createTheme({...defaultTheme, ...overrides }),
};

export default themeHoc;