import React, {useState, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Reaptcha from "reaptcha";
//import Grid from '@material-ui/core/Grid';
//import Link from '@material-ui/core/Link';
//import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField'
import Typography from '../Main/Typography';
import Button from '../Main/Button';

import service from "../../shared/services";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

// imgs
//import logoi from "../../static/images/tonic3_logo_w.svg";
import logo from "../../static/images/tonic3_logo.svg";

// context
import { SessionContext } from "../../context/SessionContext";
//import { LocaleContext } from "../../context/LocaleContext";

const useStyles = makeStyles((theme) => ({

  logoImageLogin: {
    width: 80,
    marginBottom: theme.spacing(2),
  },
  logoLoginContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: 320,
  },
  greeting: {
    display: "block",
    fontWeight: 500,
    textAlign: "center",
  },
  logoTextLogin: {
    fontWeight: 500,
    textAlign: "center",
    fontSize: 30,
  },
  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  recaptcha: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Fullname is required"),
  email: Yup.string().required("Email is required").email("Email is invalid"),
});

export default function Signup(props) {
  const { handleClose } = props;
  const classes = useStyles();

    // global
    const { session, setSession, setIsRegister } = useContext(SessionContext);

    // local
    const [serverStatus, setServerStatus] = useState(service.IDLE);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
  
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(validationSchema),
    });
  
    const onSubmit = async (data) => {
      setServerStatus(service.LOADING);
      try {
        const { authToken, isRegister } = await service.login(data);
        localStorage.setItem("authTokenThirdLive", authToken);
        setSession({
          ...session,
          authToken,
        });
        handleClose();
        setIsRegister(isRegister);
      } catch (e) {
        setServerStatus(service.ERROR);
      }
    };
  return (
    <div className={classes.formContainer}>
      <div className={classes.form}>
        <React.Fragment>
          <div className={classes.logoLoginContainer}>
            <img src={logo} alt="logo" className={classes.logoImageLogin} />

            {/*<Typography variant="h1" className={classes.logoTextLogin}>
              Talks!
            </Typography>*/}
          </div>
          <Typography variant="h5" className={classes.greeting}>
            Welcome to AR/VR Introduction
          </Typography>
          <TextField
            id="email"
            name="email"
            type="email"
            label="Email"
            margin="normal"
            //placeholder="Email Adress"
            {...register("email")}
            helperText={errors?.email ? errors?.email.message : null}
            error={Boolean(errors.email)}
            //autoComplete="off"
            fullWidth
          />
          <TextField
            id="name"
            name="name"
            type="text"
            label="Full Name"
            margin="normal"
            //placeholder="Full Name"
            {...register("name")}
            helperText={errors?.name ? errors.name.message : null}
            error={Boolean(errors.name)}
            //autoComplete="off"
            fullWidth
          />
          <div className={classes.recaptcha}>
            <Reaptcha
              sitekey="6LfGK1QcAAAAACcEB1LaKmTYTBnxZI3s6ViFyazO"
              onVerify={(token) => setRecaptchaToken(token)}
              onExpire={() => setRecaptchaToken(null)}
            />
          </div>
          <Typography variant="body1" color="error">
            {!recaptchaToken && errors.captcha}
          </Typography>
          <div className={classes.formButtons}>
            <Button
              disabled={serverStatus === service.LOADING || !recaptchaToken}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              fullWidth
            >
              {serverStatus === service.LOADING
                ? "Please wait..."
                : "Sign Up"}
            </Button>
          </div>
        </React.Fragment>
      </div>

    </div>
  );
}