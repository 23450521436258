import React, {useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from './Button';

function Understand(){

  const PolicyMessage = () => (
      <div>
        <p>This site uses cookies to collect information about your browsing activities in order to provide you with relevant content and help us understand your interests. By continuing to browse this site you agree to the use of cookies. Visit our <a href='https://tonic3.com/cookie-information' target="_blank" rel="noreferrer">cookies policy</a> to learn more.</p>
        <Button variant="contained" size="small" onClick={acceptCookiesFn}>I Understand</Button>
      </div>
    );

  const [cookies, setCookies] = useState(false);
  useEffect(() => {
  if (localStorage.getItem("cookies") !== null) {
    setCookies(true);
  } else {
    setCookies(false);
  }
  }, []);

  const acceptCookiesFn = () => {
    localStorage.setItem("cookies", "cookies_accepted");
    setCookies(true);
  };  

  return cookies ? null : (
    <div>
      
      <ToastContainer
      position="bottom-center"
      autoClose={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      style={{width: "90%"}
    }
    >{toast(PolicyMessage)}</ToastContainer>
    </div>
  );
}

export default (Understand);