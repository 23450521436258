import React, { createContext, useState } from 'react';

export const CountdownContext = createContext({
  isCountdownFinish: false,
  isScrollActive: false,
  setIsCountdownFinish: () => { },
});

export const CountdownProvider = ({ children }) => {
  const [isCountdownFinish, setIsCountdownFinish] = useState(false);
  const [isScrollActive, setIsScrollActive] = useState(false);

  return (
    <CountdownContext.Provider
      value={{
        isCountdownFinish,
        setIsCountdownFinish,
        isScrollActive,
        setIsScrollActive,
      }}
    >
      {children}
    </CountdownContext.Provider>
  );
};
