import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '../Main/Typography';
import Button from '../Main/Button';
import Copyright from '../Main/Copyright';

//icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons'
import backgroundImage from '../../static/images/bglogofooter.png';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor: theme.palette.background.custom2,
        color: theme.palette.primary.contrastText,
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundPositionY: 45,
        [theme.breakpoints.up('md')]: {
            backgroundPosition: 'top right',
            backgroundSize: 'auto',
            backgroundPositionY: 45,
        },
    },
    container: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
    },
    gridCont: {
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            alignItems: 'inherit',
        },
    },
    iconsWrapper: {
        marginTop: theme.spacing(8),
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            alignItems: 'inherit',
            justifyContent: 'space-between',
        },
    },
    icons: {
        display: 'flex',
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up('md')]: {
            marginBottom: 'inherit',
        },
    },
    icon: {
        color: theme.palette.primary.contrastText,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: theme.spacing(2),
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
        },
        '&:last-child': {
            marginRight: 'inherit',
        },
    },
}));

export default function AppFooter() {
    const classes = useStyles();

    return (
        <Typography component="footer" className={classes.root}>
            <Container className={classes.container}>
                <Grid className={classes.gridCont}
                    container
                    direction="column"
                    justifyContent="flex-end"
                    spacing={4}
                >
                    <Grid item>
                        <Typography variant="h4">
                            Want to start your project?
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Button variant="outlined" color="secondary" target="_blank" href="https://tonic3.com/contact">
                            Contact us
                        </Button>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    className={classes.iconsWrapper}
                >
                    <Grid item className={classes.icons}>
                        <Link className={classes.icon} target="_blank" href="https://facebook.com/tonic3com" ><FontAwesomeIcon size="lg" icon={faFacebookF} /></Link>
                        <Link className={classes.icon} target="_blank" href="https://twitter.com/tonic3com" ><FontAwesomeIcon size="lg" icon={faTwitter} /></Link>
                        <Link className={classes.icon} target="_blank" href="https://www.instagram.com/tonic.3/" ><FontAwesomeIcon size="lg" icon={faInstagram} /></Link>
                        <Link className={classes.icon} target="_blank" href="https://www.linkedin.com/company/tonic3/mycompany/" ><FontAwesomeIcon size="lg" icon={faLinkedinIn} /></Link>
                        <Link className={classes.icon} target="_blank" href="https://www.youtube.com/channel/UChXwjAc85HfHIlakQZhO0EA" ><FontAwesomeIcon size="lg" icon={faYoutube} /></Link>
                    </Grid>
                    <Grid item >
                        <Copyright />
                    </Grid>
                </Grid>

            </Container>
        </Typography>
    );
}