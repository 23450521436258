import React from "react";
import Link from '@material-ui/core/Link';

export default function Copyright() {
    return (
        <React.Fragment>
            {'© ' + new Date().getFullYear() + ' '}
            <Link color="inherit" href="https://tonic3.com/" target="_blank" >
                Tonic3.com
            </Link>{'  | Talks! All rights reserved'}

        </React.Fragment>
    );
}
