import React, { createContext, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

//import service from '../shared/services';

//const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8080';
//const TRACK_EVENT = `${API_URL}/track/leave`;

document.SESSION_DATA = {};

export const SessionContext = createContext({
  session: null,
  setSession: () => { },
  isRegister: false,
  setIsRegister: () => { },
  streamingVisited: false,
  setStreamingVisited: () => { },
});

export const SessionProvider = ({ children }) => {
  //const [serverStatus, setServerStatus] = useState(service.IDLE);
  const [session, setSession] = useState(null);
  const [isRegister, setIsRegister] = useState(false);
  const [streamingVisited, setStreamingVisited] = useState(false);

  document.SESSION_DATA = { ...session };

  useEffect(() => {
    const uuid = uuidv4();
    localStorage.removeItem('authToken')
    const authToken = localStorage.getItem('authTokenThirdLive');
    setSession({ authToken, uuid });
  }, []);
  /**
    useEffect(() => {
      document.addEventListener('visibilitychange', function () {
        const { uuid, authToken } = document.SESSION_DATA;
        const { country, region } = document.LOCALE_DATA;
        if (uuid && authToken) {
          if (document.visibilityState === 'hidden') {
            navigator.sendBeacon(
              `${TRACK_EVENT}?event=leave&uuid=${uuid}&country=${country}&region=${region}&authToken=${authToken}`,
            );
          }
          if (document.visibilityState === 'visible') {
            navigator.sendBeacon(
              `${TRACK_EVENT}?event=re-enter&uuid=${uuid}&country=${country}&region=${region}&authToken=${authToken}`,
            );
          }
        }
      });
    }, []);
   
  useEffect(() => {
    async function fetchData() {
      if (session) {
        const { uuid, authToken } = session;
        const { country, region } = document.LOCALE_DATA;

        const event = 'enter';

        if (uuid && authToken) {
          const data = {
            event,
            country,
            region,
            uuid,
          };
          try {
            await service.trackEvent(data, authToken);
          } catch (e) {
            localStorage.removeItem('authToken');
            setSession({ uuid });
          }
        }
        setServerStatus(service.SUCCESS);
      }
    }
    fetchData();
  }, [session]);
*/

  return (

    <SessionContext.Provider
      value={{ session, setSession, isRegister, setIsRegister, streamingVisited, setStreamingVisited }}
    >
      {children}
    </SessionContext.Provider>

  );
};

export const signOut = (history) => {
  //console.log("history", history);
  localStorage.removeItem("authTokenThirdLive");
  history.push("/signup");
}
