import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import Typography from "../Main/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Button from '../Main/Button';
import img1 from "../../static/images/igfeed1.jpg";
import img2 from "../../static/images/igfeed2.jpg";
import img3 from "../../static/images/igfeed3.jpg";
import img4 from "../../static/images/igfeed4.jpg";
import img5 from "../../static/images/igfeed5.jpg";
import img6 from "../../static/images/igfeed6.jpg";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// swiper bundle styles
import "swiper/swiper-bundle.min.css";

// swiper core styles
import "swiper/swiper.min.css";

// modules styles
import "../../shared/themes/styles/navigation.min.css";
import "../../shared/themes/styles/pagination.min.css";

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Autoplay]);

const styles = (theme) => ({
  root: {
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#E5E5E5",
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: "flex",
    position: "relative",
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  cardTitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  cardSubtitle: {
    color: theme.palette.text.primary,
  },
  cardSubtitle2: {
    color: theme.palette.text.hint,
  },
  cardText: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  swiperCont: {
    paddingBottom: theme.spacing(5),
  },
  media: {
    height: "100%",
    width: "100%",
  },
});

function SectionInstagram(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Typography
        color="inherit"
        align="center"
        variant="h1"
        marked="center"
        className={classes.title}
      >
        Instagram
      </Typography>

      <Button
        variant="outlined"
        color="primary"
        target="_blank"
        href="https://www.instagram.com/tonic.3/"
      >
        Follow us
      </Button>

      <Container className={classes.container}>
        <Swiper
          className={classes.swiperCont}
          spaceBetween={20}
          pagination={{ clickable: true }}
          loop={true}
          //autoplay={{ delay: 4000, disableOnInteraction: false }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              slidesPerGroup: 1,
              //spaceBetween: 20
            },
            // when window width is >= 
            800: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              //spaceBetween: 20
            },
            // when window width is >= 
            998: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              //spaceBetween: 20
            }
          }}
          navigation={
            true
            //{ nextEl: '.swiper-button-next-unique', prevEl: '.swiper-button-prev-unique' }
          }
        >
          <SwiperSlide>
            <Card variant="outlined">
              <CardActionArea
                href="https://www.instagram.com/p/CQgtnFJt8it/"
                target="_blank"
              >
                <CardMedia
                  className={classes.media}
                  component="img"
                  src={img1}
                  title="Design"
                />
              </CardActionArea>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card variant="outlined">
              <CardActionArea
                href="https://www.instagram.com/p/CRZd9wFNDYh/"
                target="_blank"
              >
                <CardMedia
                  className={classes.media}
                  component="img"
                  src={img2}
                  title="Agile Development"
                />
              </CardActionArea>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card variant="outlined">
              <CardActionArea
                href="https://www.instagram.com/p/CR4XgVzt5fZ/"
                target="_blank"
              >
                <CardMedia
                  className={classes.media}
                  component="img"
                  src={img3}
                  title="Why you should choose us"
                />
              </CardActionArea>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card variant="outlined">
              <CardActionArea
                href="https://www.instagram.com/p/CQy4uzVjiAy/"
                target="_blank"
              >
                <CardMedia
                  className={classes.media}
                  component="img"
                  src={img4}
                  title="Defined Brand"
                />
              </CardActionArea>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card variant="outlined">
              <CardActionArea
                href="https://www.instagram.com/p/CPtSEd6gTDN/"
                target="_blank"
              >
                <CardMedia
                  className={classes.media}
                  component="img"
                  src={img5}
                  title="Defined Brand"
                />
              </CardActionArea>
            </Card>
          </SwiperSlide>
          <SwiperSlide>
            <Card variant="outlined">
              <CardActionArea
                href="https://www.instagram.com/p/CRUUZoYtAM4/"
                target="_blank"
              >
                <CardMedia
                  className={classes.media}
                  component="img"
                  src={img6}
                  title="Defined Brand"
                />
              </CardActionArea>
            </Card>
          </SwiperSlide>
        </Swiper>
      </Container>
    </section>
  );
}

SectionInstagram.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SectionInstagram);
